<template>

<v-col class="mb-5 pb-5">
  <v-row>
    <v-col cols="12" sm="9" md="8" lg="6" class="text-center mx-auto header-section">
      <v-icon class="d-none d-md-flex" style="font-size:6em; margin-bottom:-70px;" color="red">mdi-qrcode</v-icon>
      <v-icon class="d-md-none" style="font-size:4em; margin-bottom:-20px;" color="red">mdi-qrcode</v-icon>
      <h2 class="mt-3 ml-4 red--text">Parrainage</h2>
      <small class="font-papyrus d-block" style="margin-top:50px;">
        Participez au recrutement de nouveaux pirates !<br><br>
        Le Trésor Public vous offre 1 PRT<br>
        pour chaque nouveau pirate recruté !
      </small>
      <v-divider class="my-5"></v-divider>
      <h4 class="mt-3 red--text font-papyrus">Votre code de parrainage :</h4>
      <h3 class="mt-3 red--text font-led">{{ $store.state.auth.user.recruitCode }}</h3>
      <small>
        <b>{{ $store.state.auth.user.recruitCode }}</b>
        <br><br>
        Tout nouveau pirate utilisant ce code de parrainage
        <br>lors de son inscription vous fera gagner 1 PRT.
        <br>Et gagnera lui-même aussi 1 PRT !
      </small>
      <v-divider class="my-5"></v-divider>
      <small><b>Scannez le QR-code<br>pour aller encore plus vite !</b></small><br><br>
      <div class="d-inline-block text-center" id="qrcode">
        <qr-code :text="qrhref"></qr-code>
      </div>
      <br><br><br><br><br><br><br><br><br><br><br>
    </v-col>
  </v-row>
</v-col>

</template>

<style >
#qrcode img{
  border: 5px solid white;
}
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'
import VueQRCodeComponent from 'vue-qrcode-component'
const config = require('../config/' + process.env.NODE_ENV)

export default {
  name: 'empty',
  components: { 'qr-code' : VueQRCodeComponent },
  data: () => ({
    
    
  }),
  async mounted(){
    this.$store.dispatch('app/incStat', '/recruit')
  },
  methods: {
    

  },
  computed: {
    qrhref(){
      return config.domaineUrl + "/register/" + this.$store.state.auth.user.recruitCode //"https://lamonnaiepirate.net"
    }
    
  }
}
</script>
